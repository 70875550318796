<!--
 * @Descripttion: 回复查询
 * @Author: zyr
 * @Date: 2021-05-29 15:16:29
 * @LastEditors: zyr
 * @LastEditTime: 2021-07-15 12:44:47
-->
<template>
  <div class="home-wrapper">
    <div>
      <div class="empty" v-if="!list.length">
        <img src="../../assets/imgs/empty.png" alt="" class="empty-img"/>
        <p class="empty-txt">当前暂无信息</p>
      </div>
      <div v-else class="list-wrapper">
        <router-link class="reply-item" v-for="(v, i) in list" :key="i" :to="'/detail?id=' + v.id">
          <img :src="imgs[v.status]" alt="" class="reply-img flex-shrink"/>
          <div class="flex-grow reply-con">
            <div class="flex justify-between">
              <div class="reply-type">类型：{{v.type | getType(typeList)}}</div>
              <span class="reply-date">{{v.date | getDate}}</span>
            </div>
            <div class="reply-txt">{{v.title}}</div>
          </div>
        </router-link>
      </div>
    </div>
    <!-- <div class="home-footer">
      <p class="text-align">XX提供技术支持</p>
      <a href="tel:0511-3455899" class="footer-phone text-align">0511-3455899</a>
    </div> -->
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { getType, letterList } = API
export default {
  name: 'Reply',
  data () {
    return {
      list: [],
      imgs: [
        require('@_img/no-read.png'),
        require('@_img/read.png')
      ],
      typeList: []
    }
  },
  filters: {
    getType (type, typeList) {
      const item = typeList.find(v => v.id === type)
      return item ? item.name : ''
    },
    getDate (date) {
      return date.split(' ')[0].slice(5)
    }
  },
  methods: {
    getActions () {
      this.$axios({
        url: getType,
        method: 'get',
        headers: { tkn: this.$store.state.token }
      }).then(({ code, msg, model }) => {
        if (code === 0) this.typeList = model
      })
    },
    getList () {
      this.$axios({
        url: letterList,
        method: 'get',
        headers: { tkn: this.$store.state.token }
      }).then(({ code, msg, model }) => {
        if (code === 0) this.list = model
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getActions()
      vm.getList()
    })
  }
}
</script>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
@import '../../assets/less/common.less';
.empty{
  padding-top: .px2rem(302px)[@rem];
}
.empty-img{
  display: block;
  width: .px2rem(368px)[@rem];
  margin:  0 auto .px2rem(60px)[@rem];
}
.empty-txt{
  text-align: center;
  font-size: .px2rem(26px)[@rem];
  color: #B0B8BF;
}
.list-wrapper{
  padding-top: .px2rem(58px)[@rem];
  margin-left: .px2rem(32px)[@rem];
}
.reply-item{
  display: flex;
  align-items: center;
  margin-bottom: .px2rem(32px)[@rem];
}
.reply-img{
  width: .px2rem(96px)[@rem];
  margin-right: .px2rem(32px)[@rem];
}
.reply-con{
  height: .px2rem(116px)[@rem];
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: .px2rem(40px)[@rem];
  border-bottom: .px2rem(1px)[@rem] solid #ededed;
}
.reply-type{
  color: #101112;
  font-size: .px2rem(32px)[@rem];
  line-height: 1;
}
.reply-txt{
  margin-top: .px2rem(18px)[@rem];
  color: #8993A8;
  font-size: .px2rem(26px)[@rem];
}
.reply-date{
  color: #C1C3C7;
  font-size: .px2rem(26px)[@rem];
}
</style>
